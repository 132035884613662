import React, { useState } from "react";
import { MdOutlineFilterList } from "react-icons/md";
import { AiOutlineTeam } from "react-icons/ai";
import { IoPersonOutline } from "react-icons/io5";
import {
  GoGraph,
  GoGoal,
  GoTriangleRight,
  GoTriangleDown,
} from "react-icons/go";
import { PiCircleDashed } from "react-icons/pi";
import { BiGlobe } from "react-icons/bi";
import { Checkbox, Progress } from "antd";
import { getAvatarColor, getInitials } from "../../../utils/helpers";
import {
  Box,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
} from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";
import { BsTriangleFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import {
  handleOpenGoalDrawer,
  handleOpenKeyresultDrawer,
  handleOpenOkrDrawer,
} from "../../../stores/slices/okrSlice";

const GoalsTable = ({
  goals,
  selectedGoalIndex,
  handleCheckboxChange,
}) => {
  const dispatch = useDispatch();
  const [expandedGoal, setExpandedGoal] = useState(null);
  const [expandedObjective, setExpandedObjective] = useState({});

  const handleGoalToggle = (goalId) => {
    setExpandedGoal((prev) => (prev === goalId ? null : goalId));
  };

  const handleOpenDrawerWithKey = (node) => {
    console.log(node, "node");
    if (node?.key_id) {
      dispatch(handleOpenKeyresultDrawer(node?.key_id));
    } else if (node?.okr_id) {
      dispatch(handleOpenOkrDrawer(node?.okr_id));
    } else if (node?.goal_id) {
      dispatch(handleOpenGoalDrawer(node?.goal_id));
    } else {
    }
  };

  const handleObjectiveToggle = (objectiveId, index) => {
    setExpandedObjective((prev) => ({
      ...prev,
      [objectiveId]: !prev[objectiveId],
    }));
  };

  const renderOwners = (owners, type) => (
    <Box
      maxHeight="4rem"
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
      }}
    >
      {owners
        ?.filter((owner) => owner?.type === type)
        .map((owner, index) => (
          <Box
            key={`owner-${type}-${index}`}
            display="flex"
            alignItems="center"
            gap={2}
          >
            {type === "team" ? (
              <Box p={1} h={5} w={5} bg="gray.600" rounded="full">
                <BiGlobe className="text-gray-300 text-xs justify-center items-center" />
              </Box>
            ) : (
              <Box
                w={4}
                h={4}
                border="1px"
                borderColor="black"
                color={"white"}
                rounded="full"
                bg={
                  owner?.profile_image ? "initial" : getAvatarColor(owner?.name)
                }
                display="flex"
                fontSize="smaller"
                alignItems="center"
                justifyContent="center"
              >
                {owner?.profile_image ? (
                  <Image
                    src={owner?.profile_image}
                    alt="Profile"
                    borderRadius="full"
                    boxSize="100%"
                    objectFit="cover"
                  />
                ) : (
                  getInitials(owner?.name)
                )}
              </Box>
            )}
            <Text fontSize="xs" fontWeight="medium">
              {owner?.name}
            </Text>
          </Box>
        ))}
    </Box>
  );

  const renderProgress = (gain) => (
    <Box>
      <div className="flex flex-col w-3/4 items-center justify-center gap-x-2 col-span-1">
        <div className="flex justify-between w-3/4">
          <div className="text-xs" style={{ color: "#0C0C0C" }}>
            {gain === 0 ? "Pending" : gain < 100 ? "In Progress" : "Completed"}
          </div>
          <div>
            <h3 className="text-xs font-bold" style={{ color: "#063F5D" }}>
              {gain}
              <span className="font-medium">%</span>
            </h3>
          </div>
        </div>
        <div className="flex justify-center w-full bg-inherit items-center">
          <Progress
            percent={gain}
            showInfo={false}
            strokeColor={gain < 100 ? "#F77C33" : "#0AA959"}
          />
        </div>
      </div>
    </Box>
  );

  const renderDescription = (description) => (
    <Text fontSize="sm" opacity={0.7}>
      {description}
    </Text>
  );

  const renderChildren = (children, paddingLeft = 0) => (
    <>
      {children?.map((item, index) => (
        <React.Fragment key={`child-${index}`}>
          <Tr border="1px solid" borderColor="gray.300">
            <Td pl={paddingLeft} border="1px solid" borderColor="gray.300">
              <Box display="flex" alignItems="center">
                <button
                  className="mr-2"
                  onClick={() =>
                    item?.children
                      ? handleObjectiveToggle(item?.id, index)
                      : null
                  }
                  aria-label="Toggle"
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingRight: 0,
                  }}
                >
                  {!item?.children?.length ? (
                    <FaCircle color="#7C8091" size={8} />
                  ) : expandedObjective === expandedObjective[index] ? (
                    <BsTriangleFill
                      color="#7C8091"
                      size={12}
                      style={{ transform: "rotate(180deg)" }}
                    />
                  ) : (
                    <BsTriangleFill
                      color="#7C8091"
                      size={12}
                      style={{ transform: "rotate(90deg)" }}
                    />
                  )}
                </button>
                <button
                  onClick={() => handleOpenDrawerWithKey(item)}
                  className="mr-2 cursor-pointer"
                >
                  <img
                    src={
                      item?.key_id
                        ? "/assets/images/keyresults.svg"
                        : "/assets/images/objective.svg"
                    }
                    style={{ width: "20px", height: "20px" }}
                    alt={item?.key_id ? "key result" : "objective"}
                  />
                </button>
                <Text onClick={() => handleOpenDrawerWithKey(item)} className="cursor-pointer" fontSize="sm" fontWeight="medium">
                  {item?.title || item?.name}
                </Text>
              </Box>
            </Td>
            <Td border="1px solid" borderColor="gray.300">
              {renderOwners(item?.owners, "team")}
            </Td>
            <Td border="1px solid" borderColor="gray.300">
              {renderOwners(item?.owners, "user")}
            </Td>
            <Td border="1px solid" borderColor="gray.300">
              {renderProgress(item?.overall_gain)}
            </Td>
            <Td
              border="1px solid"
              borderColor="gray.300"
              style={{
                maxWidth: "300px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {renderDescription(item?.description)}
            </Td>
          </Tr>
          {expandedObjective[item?.id] &&
            renderChildren(item?.children, paddingLeft + 8)}
        </React.Fragment>
      ))}
    </>
  );

  return (
    <Box className="overflow-x-auto overflow-y-hidden">
      <Table className="table-auto w-full border-collapse border  overflow-x-auto overflow-y-hidden border-gray-300 rounded-lg">
        <Thead>
          <Tr className="font-normal border h-12 rounded-lg">
            <Th className="px-2 py-2 text-left border-r">
              <div className="flex gap-x-2">
                <MdOutlineFilterList /> Name
              </div>
            </Th>
            <Th className="px-2 py-2 text-center border-r">
              <div className="flex gap-x-2">
                <AiOutlineTeam /> Team
              </div>
            </Th>
            <Th className="px-2 py-2 text-center border-r">
              <div className="flex gap-x-2">
                <IoPersonOutline /> Owners
              </div>
            </Th>
            <Th className="px-2 py-2 text-center border-r">
              <div className="flex gap-x-2">
                <GoGraph /> Progress
              </div>
            </Th>
            <Th className="px-2 py-2 text-center">
              <div className="flex gap-x-2">
                <PiCircleDashed /> Description
              </div>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {goals?.slice()?.map((goal, i) => (
            <React.Fragment key={`goal-${i}`}>
              <Tr>
                <Td
                  className=" h-full"
                  px={2}
                  py={2}
                  border="1px solid"
                  borderColor="gray.300"
                >
                  <Box className="flex">
                    <button
                      className="mr-2"
                      onClick={() =>
                        goal?.goal_id ? handleGoalToggle(goal?.goal_id) : null
                      }
                      aria-label="Toggle"
                      style={{
                        background: "transparent",
                        border: "none",
                        paddingRight: 0,
                      }}
                    >
                      {goal?.children?.length === 0 ? (
                        <FaCircle color="#7C8091" size={8} />
                      ) : expandedGoal === goal?.goal_id ? (
                        <BsTriangleFill
                          color="#7C8091"
                          size={12}
                          style={{ transform: "rotate(180deg)" }}
                        />
                      ) : (
                        <BsTriangleFill
                          color="#7C8091"
                          size={12}
                          style={{ transform: "rotate(90deg)" }}
                        />
                      )}
                    </button>

                    <Box
                      onClick={() => handleOpenDrawerWithKey(goal)}
                      className="flex h-full justify-center cursor-pointer items-center mr-2"
                    >
                      <button
                        className="rounded mr-2 p-1"
                        style={{
                          background:
                            "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                        }}
                      >
                        <GoGoal
                          className="text-md "
                          style={{
                            color: "#B91C1C",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </button>
                      <Text
                        fontSize="sm"
                        display="flex"
                        flexDirection={"row"}
                        alignItems="center"
                        fontWeight="medium"
                        height="100%"
                      >
                        {goal?.title || goal?.goal_name}
                      </Text>
                    </Box>
                    <Checkbox
                      className="ml-4"
                      isChecked={selectedGoalIndex === i}
                      isDisabled={
                        selectedGoalIndex !== i && selectedGoalIndex >= 0
                      }
                      onChange={() => handleCheckboxChange(i, goal)}
                      style={{
                        padding: "0",
                        margin: "0",
                        width: "fit-content",
                      }}
                    />
                  </Box>
                </Td>
                <Td border="1px solid" borderColor="gray.300">
                  {renderOwners(goal?.owners, "team")}
                </Td>
                <Td border="1px solid" borderColor="gray.300">
                  {renderOwners(goal?.owners, "user")}
                </Td>
                <Td border="1px solid" borderColor="gray.300">
                  {renderProgress(goal?.overall_gain)}
                </Td>
                <Td border="1px solid" borderColor="gray.300">
                  {renderDescription(goal?.description)}
                </Td>
              </Tr>
              {expandedGoal === goal?.goal_id &&
                renderChildren(goal?.children, 8)}
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default GoalsTable;
