import { Drawer, Dropdown, Select, Tooltip, TreeSelect } from "antd";
import React, { useEffect, useState } from "react";
import { BiGlobe } from "react-icons/bi";
import { GoGoal } from "react-icons/go";
import { Sessions } from "../../../utils/Constants";
import { getAvatarColor, getInitials } from "../../../utils/helpers";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../utils/config";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  handleCloseGoalDrawer,
  handleOpenCopyGoalModal,
  handleOpenMoveGoalModal,
} from "../../../stores/slices/okrSlice";
import MoveModal from "../Move/MoveGoalModal";
import CopyModal from "../Copy/CopyGoalModal";
import { useNavigate } from "react-router-dom";

const getId = (okrData) => {
  if (okrData?.key_id) {
    return okrData?.key_id;
  } else if (okrData?.okr_id) {
    return okrData?.okr_id;
  } else if (okrData?.goal_id) {
    return okrData?.goal_id;
  }
};
const GoalDrawerComponent = ({ drawerData, getOkrs, okrData }) => {
  const [formData, setformData] = useState({
    // ...drawerData,
  });
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [owners, setOwners] = useState([]);
  const [parentDropdownItems, setParentDropdownItems] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const okrSlice = useSelector((state) => state.okr);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  useEffect(() => {
    if (drawerData?.owners?.length) {
      let tempOwnerList = drawerData?.owners?.map(
        (owner) => owner?.id + " " + owner?.type
      );
      setSelectedOwner(tempOwnerList);
    }
  }, [drawerData]);

  useEffect(() => {
    if (drawerData?.session){
      const session = typeof drawerData?.session === 'string' ? (isNaN(drawerData.session) ? JSON.parse(drawerData.session.replace(/'/g, '"')).value : parseInt(drawerData.session)) : drawerData.session;
      setformData({ ...formData, sesssion: session });
  }
  }, [drawerData]);

  const fetchData = async () => {
    try {
      let company_id;
      if (currentEmployee) {
        company_id = currentEmployee?.company_id;
      }
      const response = await axios.get(baseURL + "/assignowners/", {
        params: {
          company_id: company_id,
        },
      });
      setOwners(response.data);
    } catch (error) {
      // setError(error);
      console.log(error);
    }
  };


  useEffect(() => {    
    if (okrSlice?.openGoalDrawer) {
      fetchData();
    }
  }, [okrSlice?.openGoalDrawer]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseURL + "/owners/", {
          params: {
            goal_id: okrSlice?.openGoalDrawer,
          },
        });
        let tempData = { ...response?.data?.children[0] };
        if (response?.data?.children[0]?.parent_type) {
          tempData.parent_val =
            (response?.data?.key_id ||
              response?.data?.okr_id ||
              response?.data?.goal_id) +
            " " +
            response?.data?.children[0]?.parent_type;
        }
        setformData({
          ...tempData,
        });
      } catch (error) {
        // setError(error);
        console.log(error);
      }
    };

    if (okrSlice?.openGoalDrawer) {
      fetchData();
    }
  }, [okrSlice?.openGoalDrawer]);

  const getParentData = async () => {
    try {
      const response = await axios.get(baseURL + "/assignparents/", {
        params: {
          company_id: currentEmployee?.company_id,
          session: okrSlice?.session,
        },
      });
      const tempParentTree = response.data?.map((goal) => ({
        label: (
          <p className="flex gap-x-2 items-center">
            <button
              className="rounded p-1"
              style={{
                background:
                  "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
              }}
            >
              <GoGoal
                className="text-md"
                style={{
                  color: "#B91C1C",
                  width: "18px",
                  height: "18px",
                }}
              />
            </button>
            {goal?.title}
          </p>
        ),
        value: goal?.goal_id + " " + "goal",
        type: "goal",
        disabled: goal?.goal_id === okrSlice?.openGoalDrawer,
        children: goal?.children?.map((okr) => ({
          label: (
            <p className="flex gap-x-2 items-center">
              <button>
                <img
                  src="/assets/images/objective.svg"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </button>
              {okr?.title}{" "}
            </p>
          ),
          value: okr?.okr_id + " " + "okr",
          type: "okr",
          disabled: goal?.goal_id === okrSlice?.openGoalDrawer,
          children: okr?.children?.map((key_result) => {
            return {
              label: (
                <p className="flex gap-x-2 items-center">
                  <button>
                    <img
                      src="/assets/images/keyresults.svg"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </button>
                  {key_result?.title}
                </p>
              ),
              disabled: goal?.goal_id === okrSlice?.openGoalDrawer,
              value: key_result?.key_id + " " + "key_result",
              type: "key_result",
            };
          }),
        })),
      }));
      setParentDropdownItems(tempParentTree);
    } catch (error) {}
  };

  useEffect(() => {
    if (okrSlice?.openGoalDrawer) getParentData();
  }, [okrSlice?.openGoalDrawer]);

  const updateGoal = async (e) => {
    let user_id = [];
    let team_id = [];
    try {
      let changeData = {};
      if (e) {
        if (!e?.target?.name) {
          changeData = {
            ["session"]: e,
          };
        }
        // if (!e?.target?.value) return;
        else {
          changeData = {
            [e?.target?.name]: e?.target?.value,
          };
        }
      } else {
        selectedOwner?.forEach((item) => {
          let typeArr = item.split(" ");
          if (typeArr[1] === "user") {
            user_id.push(typeArr[0]);
          } else {
            team_id.push(typeArr[0]);
          }
        });
        changeData = {
          user_id,
          team_id,
        };
      }

      const res = await axios.put(baseURL + `/goal/`, {
        goal_id: okrSlice?.openGoalDrawer,
        login_user_id: currentEmployee?.user_id,
        ...changeData,
      });
      // getOkrData(drawerData?.okr_id)
      getOkrs();
    } catch (error) {
      console.log(error);
    }
  };

  const updateSelectedOwner = async (e) => {
    let user_id = [];
    let team_id = [];
    selectedOwner?.forEach((item) => {
      let typeArr = item?.split(" ");
      if (typeArr[1] === "user") {
        user_id.push(typeArr[0]);
      } else {
        team_id.push(typeArr[0]);
      }
    });

    const currentEmployee = currentUser?.employees[user?.currentCompany];
    try {
      if (e) {
        let typeArr = e?.split(" ");
        if (typeArr[1] === "user") {
          user_id = user_id.filter((id) => id !== typeArr[0]);
        } else {
          team_id = team_id.filter((id) => id !== typeArr[0]);
        } // Filter out e from team_id
      }

      const res = await axios.put(baseURL + `/goal/`, {
        goal_id: okrSlice?.openGoalDrawer,
        login_user_id: currentEmployee?.user_id,
        ...{ user_id, team_id },
      });
      // getOkrData(drawerData?.okr_id)
      getOkrs();
    } catch (error) {
      console.log(error);
    }
  };

  const updateParent = async () => {
    try {
      let changeData = {};
      if (!formData?.parent_val) {
        changeData.deleted_parent = true;
      } else {
        const parentArr = formData?.parent_val.split(" ");
        changeData.parent = parentArr[0];
        changeData.parent_type = parentArr[1];
      }
      const response = await axios.put(baseURL + "/goal/", {
        ...changeData,
        goal_id: okrSlice?.openGoalDrawer,
        login_user_id: currentEmployee?.user_id,
      });
      getOkrs();
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteGoal = async () => {
    try {
      const response = await axios.delete(baseURL + "/goal/", {
        params: {
          goal_id: drawerData?.goal_id,
          login_user_id: currentEmployee?.user_id,
        },
      });
      // getOkrs()
      dispatch(handleCloseGoalDrawer());
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Drawer
        zIndex={1}
        title={<DrawerHeader handleDeleteGoal={handleDeleteGoal} />}
        placement="right"
        onClose={() => dispatch(handleCloseGoalDrawer())}
        open={okrSlice?.openGoalDrawer}
        width={550}
      >
        <div className="flex flex-col w-full p-4 gap-y-8 ">
          {parentDropdownItems?.length === 0 ? (
            <div className="h-full flex justify-center items-center mx-auto">
              <img src="/assets/images/Group 3.svg" />
            </div>
          ) : (
            <div className="flex flex-col w-full gap-y-2">
              <h2 className="flex items-center w-full p-1 text-2xl transition-all border border-transparent rounded hover:border-slate-600 gap-x-2">
                <button
                  className="rounded p-1"
                  style={{
                    background:
                      "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                  }}
                >
                  <GoGoal
                    className="text-md"
                    style={{
                      color: "#B91C1C",
                      width: "25px",
                      height: "25px",
                    }}
                  />
                </button>
                <input
                  className="text-2xl p-4 border-none"
                  name="title"
                  value={formData?.title || drawerData?.title}
                  type="text"
                  onChange={(e) =>
                    setformData({ ...formData, title: e.target.value })
                  }
                  onBlur={updateGoal}
                ></input>
              </h2>

              <div className="flex items-center w-full gap-x-8">
                <label
                  htmlFor="session-input"
                  className="w-1/6 py-5 text-xs text-slate-700"
                >
                  Session
                </label>
                <Select
                  className="w-4/5"
                  name="session"
                  value={parseInt(formData?.session)}
                  onChange={(e) => {
                    setformData({ ...formData, session: e });
                  }}
                  onBlur={() => updateGoal(formData?.session)}
                  size="large"
                >
                  {Sessions.map((session, index) => {
                    return (
                      <Select.Option value={session.value} key={index}>
                        {session.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div className="flex items-center w-full gap-x-8">
                <label
                  htmlFor="owner-input"
                  className="w-1/6 py-5 text-xs text-slate-700"
                >
                  Owner
                </label>
                <Select
                  className="w-4/5 px-0"
                  title="owner"
                  mode="multiple"
                  value={selectedOwner}
                  removeIcon={selectedOwner.length !== 1 ? undefined : ""}
                  size="large"
                  onChange={(e) => {
                    setSelectedOwner(e);
                  }}
                  onBlur={(e) => updateGoal()}
                  onDeselect={(e) => {
                    updateSelectedOwner(e);
                  }}
                  style={{ padding: "10px 0px" }}
                >
                  {owners?.map((owner, index) => {
                    return (
                      <Select.Option
                        value={owner?.id + " " + owner?.type}
                        key={index}
                      >
                        <div className="flex items-center gap-x-2  select-option-owner">
                          {owner?.type === "user" ? (
                            <div
                              className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                              style={{
                                backgroundColor: owner.profile_image
                                  ? "initial"
                                  : getAvatarColor(owner.name),
                              }}
                              size={18}
                            >
                              {owner?.profile_image ? (
                                <img
                                  src={owner?.profile_image}
                                  alt="Profile"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    display: "block",
                                  }}
                                />
                              ) : (
                                getInitials(owner.name)
                              )}
                            </div>
                          ) : (
                            <span className="p-1 bg-gray-600 rounded-full">
                              <BiGlobe className="text-gray-300 text-xs" />
                            </span>
                          )}
                          <span>{owner.name}</span>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              {/* <div className="flex items-center w-full gap-x-8">
                <label
                  htmlFor="owner-input"
                  className="w-1/6 py-5 text-xs text-slate-700"
                >
                  Parent
                </label>
                <TreeSelect
                  showSearch
                  size="large"
                  className="w-4/5"
                  value={formData?.parent_val}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  placeholder="Assign Parent"
                  allowClear
                  // defaultValue={drawerData?.parent_id+" "+"goal"}
                  // treeDefaultExpandAll
                  onChange={(newVal) => {
                    setformData({ ...formData, parent_val: newVal });
                  }}
                  onBlur={(e) => updateParent(e)}
                  treeData={parentDropdownItems}
                />
              </div> */}
              <div className="flex items-center w-full gap-x-8">
                <label
                  htmlFor="description-input"
                  className="w-1/6 py-5 text-xs text-slate-700"
                >
                  Description
                </label>
                <textarea
                  id="description-input "
                  name="description"
                  onBlur={updateGoal}
                  value={formData?.description}
                  onChange={(e) => {
                    setformData({ ...formData, description: e.target.value });
                  }}
                  className="w-5/6 p-3 transition-all border hover:border-solid border-slate-300 rounded min-h-[120px] "
                />
              </div>
            </div>
          )}
        </div>
      </Drawer>
      <MoveModal
        parentDropdownItems={parentDropdownItems}
        drawerData={drawerData}
        getOkrs={getOkrs}
      />
      <CopyModal
        parentDropdownItems={parentDropdownItems}
        drawerData={drawerData}
        getOkrs={getOkrs}
      />
    </>
  );
};

export default GoalDrawerComponent;

const DrawerHeader = ({ handleDeleteGoal, goal_id }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center justify-end p-1 gap-x-2">
     
      <Tooltip title="More Options" placement="left">
        <Dropdown
          menu={{
            items: [
             
              {
                label: "Move",
                key: "7",
                label: "Move",
                onClick: () =>
                  dispatch(
                    handleOpenMoveGoalModal({ id: goal_id, type: "goal" })
                  ),
              },
              {
                label: "Clone",
                key: "8",
                onClick: () =>
                  dispatch(
                    handleOpenCopyGoalModal({ id: goal_id, type: "goal" })
                  ),
              },

              {
                label: "Delete",
                key: "9",
                danger: true,
                onClick: handleDeleteGoal,
              },
            ],
          }}
        >
          <button className="p-2 transition-colors -rotate-90 rounded hover:bg-slate-100">
            <FiMoreHorizontal className="text-xl" />
          </button>
        </Dropdown>
      </Tooltip>
    </div>
  );
};
