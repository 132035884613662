import React, { useState, useEffect } from "react";
import { TreeSelect } from "antd";
import { Button, Modal } from "antd";
import { BiGlobe } from "react-icons/bi";
import DrawerContainer from "../../../components/Okrs/Drawer";
import AlignmentTreeView from "../../../components/Okrs/AlignmentTreeView";
import axios from "axios";
import { Select } from "antd";
import { baseURL } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { Sessions } from "../../../utils/Constants";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Tooltip } from "antd";
import {
  getAvatarColor,
  getInitials,
} from "../../../utils/helpers";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import {
  handleCloseCreateOkrModal,
  handleOpenCreateOkrModal,
  handleUpdateSession,
} from "../../../stores/slices/okrSlice";
import GoalDrawerComponent from "../../../components/Okrs/Goals/GoalDrawerComponent";
import { GoGoal } from "react-icons/go";

export const ViewOkr = () => {
  const location = useLocation();

  const [formData, setformData] = useState({
    session: "",
    title: "",
    owner: [],
    // parent: "",
    // members: "",
    // description: "",
  });
  const [goalData, setGoalData] = useState();
  const [okrData, setOkrData] = useState();
  const [drawerData, setDrawerData] = useState();
  const [keyResultDrawerData, setKeyResultDrawerData] = useState();
  const [titleError, settitleError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [parentError, setParentError] = useState(false);
  const [sessionError, setSessionError] = useState(false);
  const [loadingObj, setLoadingObj] = useState(false);
  const [parentDropdownItems, setParentDropDownItems] = useState();
  const currentUser = useSelector((state) => state.user?.currentUser);
  const okrSlice = useSelector((state) => state.okr);
  const user = useSelector((state) => state.user);
  const plan = user?.currentPlan;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { goalid } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const savesession = searchParams.get("session");


  if (plan?.plan_name === "Free" && !plan?.free_trial_status) {
    navigate("/company/billing");
  }

  useEffect(() => {
    if (savesession) {
      dispatch(handleUpdateSession(savesession));
    }
  }, [savesession]);

  useEffect(() => {
    setformData({
      parent_val: okrData?.goal_id + " " + "goal",
      title: "",
      description: "",
    });
  }, [okrData]);

  const fetchParent = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await axios.get(baseURL + "/assignparents/", {
        params: {
          company_id: currentEmployee?.company_id,
          session: okrSlice?.session,
        },
      });
      const tempParentTree = response.data?.map((goal) => {
        return {
          label: (
            <p className="flex gap-x-2 items-center">
              <button
                className="rounded p-1"
                style={{
                  background:
                    "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                }}
              >
                <GoGoal
                  className="text-md"
                  style={{
                    color: "#B91C1C",
                    width: "18px",
                    height: "18px",
                  }}
                />
              </button>
              {goal?.title}
            </p>
          ),
          value: goal?.goal_id + " " + "goal",
          type: "goal",
        };
      });
      setParentDropDownItems(tempParentTree);
    } catch (error) {}
  };

  const getOkrs = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
      }
      const result = await axios.get(baseURL + "/owners/", {
        params: {
          goal_id: goalid,
        },
      });
      // return
      if (result?.data?.parent) {
        setGoalData(result.data.children[0]);
        setOkrData(result.data);
      } else {
        setGoalData(result.data.children[0]);
        setOkrData(result.data?.children[0]);
      }

      fetchParent();
    } catch (error) {
      if (error?.response?.status === 404) {
        // toast.error("No data found")
        setGoalData([]);
        navigate("/company/sessions");
        setOkrData([]);
      }
      console.log(error);
    }
  };
  useEffect(() => {
    getOkrs();
  }, []);

  function changeHandeler(event) {
    const { name, value } = event.target;
    if (name === "title" && value) {
      settitleError(false);
    }
    if (name === "session") {
      setSessionError(false);
    }
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const showModal = () => {
    dispatch(handleOpenCreateOkrModal(true));
  };


  const handleCancel = () => {
    setformData((prevFormData) => ({
      ...prevFormData,
      title: "",
      owner: [],
    }));
    dispatch(handleCloseCreateOkrModal());
  };

  const saveData = async (event) => {
    event.preventDefault();

    let user_id = [];
    let team_id = [];
    let parentGoal;
    if (!formData.title) {
      settitleError(true);
      return;
    }

    if (!formData?.owner?.length) {
      setOwnerError(true);
      return;
    }
    if (formData?.session === null || formData?.session === undefined) {
      setSessionError(true);
      return;
    }
    if (!formData?.parent_val?.length) {
      setParentError(true);
      return;
    } else {
      formData?.owner?.forEach((item) => {
        let typeArr = item.split(" ");
        if (typeArr[1] === "user") {
          user_id.push(typeArr[0]);
        } else {
          team_id.push(typeArr[0]);
        }
      });
    }

    if (formData?.parent_val) {
      const parentValArr = formData?.parent_val.split(" ");
      parentGoal = parentValArr[0];
    } else {
      parentGoal = okrData?.goal_id;
    }

    setLoadingObj(true);
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      let login_user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
        login_user_id = currentEmployee[user?.currentCompany]?.user_id;
      }

      const result = await axios.post(baseURL + "/okr/", {
        session: formData?.session,
        parent: true,
        title: formData.title,
        user_id: user_id,
        team_id: team_id,
        company_id: company_id,
        goal_id: parentGoal,
        login_user_id: login_user_id,
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "okr_created",
        message: "Objective created succesfully!.",
        id: result?.data?.okr_id,
        title: result?.data?.title,
        description: result?.data?.description,
        event_ts: new Date(Date.now()).toLocaleString(),
      });
      setformData((prevFormData) => ({
        ...prevFormData,
        title: "",
        owner: [],
      }));
    } catch (err) {
      console.log(err);
      handleCancel();
      setformData((prevFormData) => ({
        ...prevFormData,
        title: "",
        owner: [],
      }));
    } finally {
      getOkrs();
      handleCancel();
      setLoadingObj(false);
    }
  };

  const [owner, setOwner] = useState([]);
  const [error, setError] = useState(null);

  const getOkrData = async (okr_id) => {
    try {
      const res = await axios.get(baseURL + "/okr/", {
        params: {
          okr_id: okr_id,
        },
      });
      setDrawerData(res.data?.children[0]);
      // setGoalOkrData(res.data)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (okrSlice?.openOkrDrawer) {
      getOkrData(okrSlice?.openOkrDrawer);
    }
  }, [okrSlice?.openOkrDrawer]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        let user_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await axios.get(baseURL + "/assignowners/", {
          params: {
            company_id: company_id,
          },
        });
        setOwner(response.data);
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };

    if (okrSlice?.openCreateOkrModal) fetchData();
  }, [okrSlice?.openCreateOkrModal, drawerData]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const handleToggleDrawer = (data) => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div className="h-screen">
      {!okrData ? (
        <div className="h-full flex justify-center items-center mx-auto">
          <img src="/assets/images/Group 3.svg" />
        </div>
      ) : okrData ? (
        <>
          <AlignmentTreeView data={okrData} getOkrData={getOkrData} />
        </>
      ) : (
        <div className="box-border m-0 ">
          <div className="flex flex-col items-center content-center justify-center min-h-screen">
            <h1 className="text-2xl font-medium text-center text-slate-500">
              No Objective here. It's time to define <br />
              your first Objective for {goalData?.title || goalData?.goal_name}!
            </h1>
            <button
              className="px-3 py-2 my-4 text-white rounded-full bg-button"
              onClick={showModal}
            >
              Create Objective
            </button>
          </div>
        </div>
      )}

      <GoalDrawerComponent
        drawerData={goalData}
        okrData={okrData}
        getOkrs={getOkrs}
      />
      <DrawerContainer
        openDrawer={openDrawer}
        handleToggleDrawer={handleToggleDrawer}
        drawerData={drawerData || okrSlice?.currentOkrData}
        keyResultDrawerData={keyResultDrawerData}
        setKeyResultDrawerData={setKeyResultDrawerData}
        getOkrData={getOkrData}
        getOkrs={getOkrs}
      />
      <Modal
        className="text-2x"
        title=""
        open={okrSlice?.openCreateOkrModal}
        // onOk={saveData}
        okText="Create Objective"
        onCancel={() => dispatch(handleCloseCreateOkrModal())}
        footer={null}
        width={550}
      >
        <div className="">
          <h1 className="p-2 m-2 text-3xl font-bold text-black">
            Create Objective
          </h1>
          <p className="p-2 m-2 text-gray-600">
            All required fields are marked with an asterisk (*).
          </p>

          <div className="bg-green">
            <form className="flex flex-col" onSubmit={saveData}>
              <div className="flex p-1 m-2 gap-7">
                <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                  Session*
                </label>
                <br />

                <div className="w-full">
                  <div className="flex gap-x-2 items-center">
                    <Select
                      name="session"
                      id="session"
                      onChange={(value) => {
                        setSessionError(false)
                        setformData({ ...formData, session: value });
                      }}
                      placeholder="Enter Session"
                      value={formData?.session}
                      required
                      // mode="multiple"
                      className="w-full"
                    >
                      {Sessions.map((session, index) => {
                        return (
                          <Select.Option value={session.value} key={index}>
                            {session.label}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    <Tooltip
                      arrow={false}
                      placement="rightTop"
                      title="Objective can be assigned to multiple people and/or teams"
                    >
                      <AiOutlineQuestionCircle className="text-gray-500 text-xl" />
                    </Tooltip>
                  </div>
                  {sessionError && (
                    <p className="text-red-500 mt-1">Session is required</p>
                  )}
                </div>
              </div>

              <div className="flex p-1 m-2 gap-7">
                <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                  Title*
                </label>
                <br />
                <div className="w-full">
                  <div className="flex gap-x-2 items-center">
                    <input
                      className="w-full rounded-lg border border-gray-300 px-2 py-1 text-sm"
                      type="text"
                      name="title"
                      id="title"
                      onChange={changeHandeler}
                      value={formData.title}
                      placeholder="Enter a Value"
                      required
                    />

                    <Tooltip
                      arrow={false}
                      placement="rightTop"
                      title="Consider a title no longer than 70 characters. it will make OKR clear, punchy and easy to remember."
                    >
                      <AiOutlineQuestionCircle className="text-gray-500 text-xl" />
                    </Tooltip>
                  </div>
                  {titleError && (
                    <p className="text-red-500 mt-1">Title is required</p>
                  )}
                </div>
              </div>

              <div className="flex p-1 m-2 gap-7">
                <label className="w-1/4 pt-2 text-sm font-normal leading-4 text-gray-700">
                  Owner*
                </label>
                <br />

                <div className="w-full">
                  <div className="flex gap-x-2 items-center">
                    <Select
                      name="=owner"
                      id="owner"
                      onChange={(val) => {
                        setformData({ ...formData, owner: val });
                        setOwnerError(false);
                      }}
                      value={formData.owner}
                      mode="multiple"
                      required
                      className="w-full  "
                      placeholder="Select Owner"
                    >
                      {owner.map((owner, index) => {
                        return (
                          <Select.Option
                            value={owner?.id + " " + owner?.type}
                            key={index}
                          >
                            <div className="flex items-center gap-x-2">
                              {owner?.type === "user" ? (
                                <div
                                  className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                                  style={{
                                    backgroundColor: owner?.profile_image
                                      ? "initial"
                                      : getAvatarColor(owner?.username),
                                  }}
                                  size={18}
                                >
                                  {owner?.profile_image ? (
                                    <img
                                      src={owner?.profile_image}
                                      alt="Profile"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                        display: "block",
                                      }}
                                    />
                                  ) : (
                                    getInitials(owner.name)
                                  )}
                                </div>
                              ) : (
                                <span className="p-1 bg-gray-600 rounded-full">
                                  <BiGlobe className="text-gray-300 text-xs" />
                                </span>
                              )}
                              <span>{owner.name}</span>
                            </div>
                          </Select.Option>
                        );
                      })}

                      {/* calling parent form  */}
                    </Select>

                    <Tooltip
                      arrow={false}
                      placement="rightTop"
                      className="text-gray-500"
                      title="You can change the owner of the Objective"
                    >
                      <AiOutlineQuestionCircle className="text-gray-500 text-xl" />
                    </Tooltip>
                  </div>
                  {ownerError && (
                    <p className="text-red-500 mt-1">Owner is required</p>
                  )}
                </div>
              </div>
              <div className="flex p-1 m-2 gap-7">
                <label
                  htmlFor="parent-input"
                  className="w-1/4 pt-2  text-xs text-slate-700"
                >
                  Parent*
                </label>

                <br />

                <div className="w-full">
                  <div className="flex items-center gap-x-2">
                    <TreeSelect
                      showSearch
                      className="w-full"
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      placeholder="Assign Parent"
                      // allowClear
                      // defaultValue={okrData?.goal_id + " " + "goal"}
                      required
                      value={formData?.parent_val}
                      // treeDefaultExpandAll
                      onChange={(newVal) => {
                        setformData({ ...formData, parent_val: newVal });
                        setParentError(false);
                      }}
                      treeData={parentDropdownItems}
                    />
                    <Tooltip
                      arrow={false}
                      placement="rightTop"
                      title="Consider a description no longer than 150 characters. it will make Goal clear, punchy and easy to remember."
                    >
                      <AiOutlineQuestionCircle className="text-xl text-gray-500 cursor-pointer" />
                    </Tooltip>
                  </div>
                  {parentError && (
                    <p className="text-red-500 mt-1">Parent is required</p>
                  )}
                </div>
              </div>

              <div className="self-end p-2 flex gap-x-4">
                <Button
                  type="default"
                  className="py"
                  htmlType="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="bg-button  text-white"
                  htmlType="submit"
                  loading={loadingObj}
                >
                  Create Objective
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};
