import { useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import style from "./style.module.css";
import axios from "axios";
import { baseURL } from "../../../utils/config";
import { useSelector } from "react-redux";

const MentionInput = ({ editState, setEditState,readOnly}) => {
  const [result, setResult] = useState(" ");
  const [mention, setMentions] = useState([]);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await axios.get(baseURL + "/assignowners/", {
          params: {
            company_id: company_id,
          },
        });
        setMentions(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  },[]);
  useEffect(() =>{
    setResult(editState);
  },[editState])

  const modifiedResponse = mention.map(item => ({
    ...item,
    display: `${item.name}`,
    id : `${item.id.toString()}`
}));




  return (
    <div className={`w-full  ${readOnly ? '' : 'border border-2 p-2 flex-shrink-0 text-base leading-4 text-51618 '}`}>
    <MentionsInput
  className={`items-center border-none mb-5`}
  value={result}
  placeholder="Please Comment" 
  style={{ 
    width: "100%", 
    height: "50%",
    outline: "none !important", 
    border: 'none',
    boxShadow: 'none' ,
    '--mentionsinput-padding': '10px',
  }}
  onFocus={(e) => { e.target.style.outline = 'none'; }} 
  readOnly={readOnly}
  disabled={readOnly}
  onChange={(e) => {
    setResult(String(e.target.value));
    setEditState(String(e.target.value));
  }}
  markup="@[__display__](id)"
>
  <Mention
    className={` items-center w-2/3 ${style.mentions__mention}`}
    data={modifiedResponse}
    renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
      <div style={{ padding: "0.5rem" }}>
        {highlightedDisplay}
        <span style={{ color: "gray", marginLeft: "0.5rem" }}>
          {suggestion.email}
        </span>
      </div>
    )}
  />
</MentionsInput>


      {/* {!readOnly && (
  <div className="flex items-center mb-2">
    <div className="flex items-center rounded-md mr-2 px-2">
      <button onClick={() => applyFormatting('bold')}>
        <strong>B</strong>
      </button>
    </div>
    <div className="flex items-center rounded-md mr-2 px-2">
      <button onClick={() => applyFormatting('italic')}>
        <em>I</em>
      </button>
    </div>
    <div className="flex items-center  rounded-md mr-2 px-2">
      <button onClick={() => applyFormatting('underline')}>
        <u>U</u>
      </button>
    </div>
    <div className="flex items-center rounded-md mr-2 px-2">
  <button onClick={() => applyFormatting('superscript')}>
    x<sup>2</sup>
  </button>
</div>

    <div className="flex items-center rounded-md mr-2 px-2">
      <button onClick={() => applyFormatting('subscript')}>
        x<sub>2</sub>
      </button>
    </div>
    <div className="flex items-center  rounded-md mr-2 px-2">
      <button onClick={() => applyFormatting('unordered-list-item')}>
      <PiListBullets />
      </button>
    </div>
    <div className="flex items-center  rounded-md px-2">
      <button onClick={() => applyFormatting('ordered-list-item')}>
      <PiListNumbersThin />
      </button>
    </div>
  </div>
)} */}
    </div>
  );
};

export default MentionInput;