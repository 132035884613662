import React, { useEffect, useState } from "react";

import { Modal } from "antd";
import axios from "axios";
import { baseURL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import TeamModal from "../../components/People/Teams/TeamModal";
import { TeamTables } from "../../components/People/Teams/TeamTables";
import TeamEditModal from "../../components/People/Teams/TeamEditModal";
import { useNavigate } from "react-router-dom";
import {
  handleUpdatePlan,
  updateTitleData,
} from "../../stores/slices/userAuthSlice";
import { useMount } from "react-use";
import { useAppContext } from "../../components/StepByStepGuide/context";
import {
  handleCreateCloseTeamDrawer,
  handleCreateTeamDrawer,
} from "../../stores/slices/okrSlice";
import FilterTeams from "./FilterTeams";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

export const Teams = () => {
  // const [showLoader, setLoader] = useState(true);
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  const [formData, setformData] = useState({
    owner: "",
    // parent_team: "",
    manager: null,
    name: "",
  });
  const [openEditModel, setOpenEditModel] = useState(false);
  const [originalFormData, setOriginalFormData] = useState({});
  function changeHandeler(event) {
    const { name, value } = event.target;
    setformData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const okrSlice = useSelector((state) => state?.okr);
  const currentPlan = user?.currentPlan;
  const currentCompany = useSelector((state) => state.user?.currentCompany);
  const [data, setdata] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formDataFilter, setformDatafilter] = useState({
    name: "",
  });

  if (currentPlan?.plan_name === "Free" && !currentPlan?.free_trial_status) {
    navigate("/company/billing");
  }

  useMount(() => {
    if (tourActive) {
      setState({ run: true, stepIndex: 6 });
      // }, 1200);
    }
  });

  const handleClick = () => {
    dispatch(handleCreateTeamDrawer());
  };

  const [visibleFields, setVisibleFields] = useState(["Name"]);

  const values = ["Name"];
  const addField = (field) => {
    setVisibleFields((prevVisibleFields) => {
      if (!prevVisibleFields.includes(field)) {
        return [...prevVisibleFields, field];
      }
      return prevVisibleFields;
    });
  };

  const removeField = (field, name) => {
    setformDatafilter((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData[name] = "";
      return updatedFormData;
    });
    setVisibleFields((prevVisibleFields) => {
      return prevVisibleFields.filter((f) => f !== field);
    });
  };

  const fetchData = async () => {
    const params = { team_name: formDataFilter?.name };
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[currentCompany]?.company_id;
      }
      const response = await axios.get(baseURL + "/create-team/", {
        params: {
          company_id: company_id,
          fields: JSON.stringify(params),
        },
      });
      setdata(response.data);
    } catch (error) {
      //  setError(error);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [formDataFilter]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await axios.get(baseURL + "/subscription/", {
        params: {
          user_id: currentCompany?.user_id,
          company_id: currentCompany?.company_id,
        },
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleOk = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
    dispatch(handleCreateCloseTeamDrawer());
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setformData({
      owner: "",
      // parent_team: "",
      manager: null,
      name: "",
    });
    dispatch(handleCreateCloseTeamDrawer());
  };
  const handleEditCancel = () => {
    setOpenEditModel(false);
    setformData({
      owner: "",
      // parent_team: "",
      manager: null,
      name: "",
    });
  };
  const handleDeleteButtonClick = () => {
    setShowBelowDiv(false);
  };

  const handleDeleteTeam = async (team_id) => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const res = await axios.delete(baseURL + "/create-team/", {
        params: {
          team_id: team_id,
          login_user_id: currentCompany?.user_id,
        },
      });
      getCurrentPlanData();
    } catch (error) {
      console.log(error);
    } finally {
      fetchData();
    }
  };

  const handleOpenEditModel = (tempData) => {
    let manager_id;
    let employees = [];
    if (tempData?.manager?.length) manager_id = tempData?.manager[0]?.user_id;
    if (tempData?.employees?.length) {
      employees = tempData?.employees?.map((employee) => employee?.user_id);
    }
    setformData({ ...tempData, manager: manager_id, members: employees });
    setOriginalFormData({
      ...tempData,
      manager: manager_id,
      members: employees,
    });
    setOpenEditModel(true);
  };

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "People",
        ClickLink: "Teams",
      })
    );
  }, []);

  const [showBelowDiv, setShowBelowDiv] = useState(false);

  return (
    <div className="h-screen p-2 bg-white">
      <div className="flex justify-end mb-5">
        <span className="flex justify-between gap-x-4">
          <span className="flex justify-center items-center relative">
            <Popover
              placement="bottom-end"
              isLazy
              lazyBehavior="keepMounted"
              onClose={() => setPopoverOpen(false)}
            >
              <PopoverTrigger>
                <Button
                  px={4}
                  py={2}
                  width={"100px"}
                  transition="all 0.2s"
                  borderRadius="md"
                  borderWidth="1px"
                  _focus={{ boxShadow: "outline" }}
                  backgroundColor="white"
                  boxShadow={popoverOpen ? "outline" : ""}
                  color="#3C93C2"
                  fontWeight={"normal"}
                  borderColor="#3C93C2"
                  _hover={{
                    bg: "#F7FAFC",
                    color: "#287199",
                    borderColor: "#287199",
                    boxShadow: "outline",
                  }}
                  className="relative"
                  onClick={() => setPopoverOpen(true)}
                >
                  <span className="flex justify-evenly items-center gap-x-2">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00017 13.7C6.80017 13.7 6.6335 13.6334 6.50017 13.5C6.36683 13.3667 6.30017 13.2 6.30017 13V7.55005L1.02517 0.900049C0.925167 0.766715 0.912667 0.633382 0.987667 0.500049C1.06267 0.366715 1.17517 0.300049 1.32517 0.300049H12.6752C12.8252 0.300049 12.9377 0.366715 13.0127 0.500049C13.0877 0.633382 13.0752 0.766715 12.9752 0.900049L7.70017 7.55005V13C7.70017 13.2 7.6335 13.3667 7.50017 13.5C7.36683 13.6334 7.20017 13.7 7.00017 13.7Z"
                        fill="#0A4464"
                      />
                    </svg>
                    <p>Filters</p> <ChevronDownIcon />
                  </span>
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <div>
                    <FilterTeams
                      filterModal={filterModal}
                      setformData={setformDatafilter}
                      formData={formDataFilter}
                      values={values}
                      removeField={removeField}
                      addField={addField}
                      visibleFields={visibleFields}
                      setVisibleFields={setVisibleFields}
                    />
                  </div>
                </PopoverContent>
              </Portal>
            </Popover>
          </span>
          {/* <span
            className="flex justify-end items-center font-medium"
            style={{ color: "#419EBD" }}
          >
            {formatDate()}
          </span> */}
          <button
            onClick={handleClick}
            className="text-[#3C93C2] rounded-md font-lato  py-1 min-w-42 px-2 border border-[#3C93C2] "
          >
            Create Teams
          </button>
        </span>
      </div>
      <div className="h-full bg-white rounded-lg">
        <div className="flex flex-col w-full">
          <TeamTables
            fetchData={fetchData}
            data={data}
            setOpenEditModel={setOpenEditModel}
            handleOpenEditModel={handleOpenEditModel}
            handleDeleteTeam={handleDeleteTeam}
          />
        </div>
      </div>

      <Modal
        title=""
        open={okrSlice?.openCreateTeamDrawer}
        onOk={handleOk}
        onCancel={handleCancel}
        width={650}
        footer={null}
      >
        <TeamModal
          fetchData={fetchData}
          formData={formData}
          setformData={setformData}
          changeHandeler={changeHandeler}
          handleCancel={handleCancel}
          onOk={handleOk}
        />
      </Modal>
      <Modal
        title=""
        open={openEditModel}
        onOk={handleOk}
        onCancel={() => {
          handleEditCancel();
        }}
        width={650}
        footer={null}
      >
        <TeamEditModal
          fetchData={fetchData}
          originalFormData={originalFormData}
          formData={formData}
          setformData={setformData}
          changeHandeler={changeHandeler}
          handleCancel={handleEditCancel}
          onOk={handleOk}
          openEditModel={openEditModel}
        />
      </Modal>
    </div>
  );
};
