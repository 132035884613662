import React, { useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button, Checkbox } from "antd";
import { toast } from "react-toastify";
import WestIcon from "@mui/icons-material/West";

const rolesData = [
  {
    id: 1,
    name: "Admin",
    subRoles: ["Manage Users", "View Logs", "Configure System"],
  },
  {
    id: 2,
    name: "Manager",
    subRoles: ["Approve Requests", "Generate Reports", "Monitor Performance"],
  },
  {
    id: 3,
    name: "CXO",
    subRoles: ["Strategic Planning", "Budget Approval", "Policy Making"],
  },
  {
    id: 4,
    name: "Employee",
    subRoles: ["View Dashboard", "Submit Requests", "Update Profile"],
  },
  {
    id: 5,
    name: "Intern",
    subRoles: ["Assist with Tasks", "Attend Meetings", "Provide Feedback"],
  },
  {
    id: 6,
    name: "HR",
    subRoles: ["Recruitment", "Employee Relations", "Payroll Management"],
  },
  {
    id: 7,
    name: "IT Support",
    subRoles: ["Troubleshoot Issues", "Manage IT Equipment", "User Training"],
  },
  {
    id: 8,
    name: "Finance",
    subRoles: ["Manage Accounts", "Process Invoices", "Budget Tracking"],
  },
];

const AccessManagement = () => {
  const [roles, setRoles] = useState(rolesData);
  const [editRoleId, setEditRoleId] = useState(null);
  const [editedRoleName, setEditedRoleName] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedSubRoles, setSelectedSubRoles] = useState({}); // Added for subRoles
  const [expandAll, setExpandAll] = useState(false);
  const [view, setView] = useState("table"); // "table" or "assign"

  const handleEditClick = (id, currentName) => {
    setEditRoleId(id);
    setEditedRoleName(currentName);
  };

  const handleInputChange = (e) => {
    setEditedRoleName(e.target.value);
  };

  const handleSave = (id) => {
    setRoles(
      roles.map((role) =>
        role.id === id ? { ...role, name: editedRoleName } : role
      )
    );
    setEditRoleId(null);
  };

  const handleKeyPress = (e, id) => {
    if (e.key === "Enter") {
      handleSave(id);
    }
  };

  const handleAssignClick = (role) => {
    setSelectedRole(role);
    setView("assign");
  };

  const handleBackClick = () => {
    setView("table");
  };

  const handleSaveAssign = () => {
    toast.success("Role has been assigned");
    setView("table");
  };

  const handleCheckboxChange = (roleId, checked) => {
    if (checked) {
      setSelectedRoles([...selectedRoles, roleId]);
      setSelectedSubRoles((prev) => ({
        ...prev,
        [roleId]: roles.find((role) => role.id === roleId).subRoles,
      }));
    } else {
      setSelectedRoles(selectedRoles.filter((id) => id !== roleId));
      setSelectedSubRoles((prev) => {
        const updatedSubRoles = { ...prev };
        delete updatedSubRoles[roleId];
        return updatedSubRoles;
      });
    }
  };

  const handleSubRoleChange = (roleId, subRole, checked) => {
    setSelectedSubRoles((prev) => {
      const updatedSubRoles = { ...prev };
      if (checked) {
        updatedSubRoles[roleId] = [...(updatedSubRoles[roleId] || []), subRole];
      } else {
        updatedSubRoles[roleId] = updatedSubRoles[roleId].filter(
          (sr) => sr !== subRole
        );
        if (updatedSubRoles[roleId].length === 0) {
          delete updatedSubRoles[roleId];
        }
      }
      return updatedSubRoles;
    });

    // If all subRoles are selected, check the parent checkbox
    const role = roles.find((r) => r.id === roleId);
    const allSubRolesSelected = role.subRoles.every((sr) =>
      selectedSubRoles[roleId]?.includes(sr)
    );
    if (allSubRolesSelected) {
      setSelectedRoles((prev) => [...prev, roleId]);
    } else {
      setSelectedRoles((prev) => prev.filter((id) => id !== roleId));
    }
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setSelectedRoles(roles.map((role) => role.id));
      const allSubRoles = roles.reduce((acc, role) => {
        acc[role.id] = role.subRoles;
        return acc;
      }, {});
      setSelectedSubRoles(allSubRoles);
    } else {
      setSelectedRoles([]);
      setSelectedSubRoles({});
    }
  };

  const handleExpandCollapse = (expand) => {
    setExpandAll(expand);
  };

  return (
    <div className="overflow-y-auto">
      <CustomScrollbar>
        <div className="p-4 bg-white rounded-lg w-full space-y-5 font-sans">
          {view === "table" ? (
            <>
              <div className="flex items-center justify-between">
                <div className="space-y-1">
                  <h1 className="text-2xl font-semibold">
                    Role Level Permission and Privileges
                  </h1>
                  <p className="text-gray-500 text-sm font-semibold">
                    Manage your team members' role level permission and
                    privileges here.
                  </p>
                </div>
                {/* Search Bar with Icon */}
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search members..."
                    className="w-full pl-4 pr-10 py-2 border border-gray-300 rounded-lg text-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pt-2 pr-3">
                    <SearchRoundedIcon
                      className="text-gray-400"
                      fontSize="small"
                    />
                  </div>
                </div>
              </div>
              <table className="border border-gray-300 min-w-full rounded-lg overflow-hidden">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 py-4 text-left text-gray-500 font-semibold rounded-tl-lg w-1/2">
                      Role Name
                    </th>
                    <th className="p-2 py-4 text-left text-gray-500 font-semibold rounded-tr-lg w-1/2">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {roles.map((role) => (
                    <tr
                      key={role.id}
                      className="border-b border-t border-gray-300"
                    >
                      <td className="p-2 py-5 font-semibold text-sm text-gray-700 w-1/2">
                        {editRoleId === role.id ? (
                          <input
                            type="text"
                            value={editedRoleName}
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleKeyPress(e, role.id);
                              }
                            }}
                            className="p-2 border border-gray-300 rounded-md w-1/2"
                          />
                        ) : (
                          role.name
                        )}
                      </td>
                      <td className="p-2 py-5 font-semibold text-sm text-left text-gray-700 w-1/2">
                        <div className="flex space-x-4 items-center">
                          {editRoleId === role.id ? (
                            <>
                              <button
                                onClick={() => handleSave(role.id)}
                                className="text-blue-500"
                              >
                                Save
                              </button>
                              <img
                                src={"/assets/images/assign.png"}
                                alt="Assign"
                                onClick={() => handleAssignClick(role)}
                                className="cursor-pointer"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                src={"/assets/images/assign.png"}
                                alt="Assign"
                                onClick={() => handleAssignClick(role)}
                                className="cursor-pointer"
                              />
                              <img
                                src={"/assets/images/edit_icon.png"}
                                alt="Edit"
                                onClick={() =>
                                  handleEditClick(role.id, role.name)
                                }
                                className="cursor-pointer"
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div>
              <div className="flex items-center justify-between pb-5 border-b border-gray-300">
                <div className="flex items-center space-x-2">
                  <WestIcon
                    className="cursor-pointer"
                    fontSize="small"
                    onClick={handleBackClick}
                  />
                  <h2 className="text-xl font-semibold text-center">
                    Assign Role
                  </h2>
                </div>
              </div>
              <div className="flex flex-col space-y-5 py-5">
                <div className="flex items-center space-x-5">
                  <Button
                    onClick={() => handleExpandCollapse(true)}
                    className="bg-blue-500 text-white"
                  >
                    Expand All
                  </Button>
                  <Button
                    onClick={() => handleExpandCollapse(false)}
                    className="bg-blue-500 text-white"
                  >
                    Collapse All
                  </Button>
                </div>
                <Checkbox
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  checked={selectedRoles.length === roles.length}
                >
                  Select All
                </Checkbox>
                {roles.map((role) => (
                  <div key={role.id}>
                    <Checkbox
                      onChange={(e) =>
                        handleCheckboxChange(role.id, e.target.checked)
                      }
                      checked={selectedRoles.includes(role.id)}
                    >
                      {role.name}
                    </Checkbox>
                    {expandAll &&
                      role.subRoles.map((subRole) => (
                        <div
                          key={subRole}
                          className="pl-6 flex flex-col space-y-2"
                        >
                          <Checkbox
                            checked={
                              selectedSubRoles[role.id]?.includes(subRole) ||
                              false
                            }
                            onChange={(e) =>
                              handleSubRoleChange(
                                role.id,
                                subRole,
                                e.target.checked
                              )
                            }
                          >
                            {subRole}
                          </Checkbox>
                        </div>
                      ))}
                  </div>
                ))}
                <div className="flex justify-end">
                  <Button
                    className="bg-blue-500 text-white"
                    onClick={handleSaveAssign}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default AccessManagement;
