import { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Confirm Deletion</ModalHeader>
      <ModalBody>Are you sure you want to delete this?</ModalBody>
      <ModalFooter>
        <Button
          backgroundColor="#FFF1E3"
          textColor={"#F27E0D"}
          px={"28px"}
          borderRadius={"20px"}
          border={"none"}
          mr={3}
          onClick={onConfirm}
        >
          Delete
        </Button>
        <Button
          variant="outline"
          borderRadius={"20px"}
          mr={3}
          px={"28px"}
          onClick={onClose}
        >
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default ConfirmationModal;
