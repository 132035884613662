import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import AdminView from "../Touchbase/AdminView";
import RoleAccess from "./RoleAccess";
import AccessManagement from "./AccessManagement";
import KPIMetrics from "./KPIMetrics";
import OrganizationHierarchy from "./OrganizationHierarchy";
import Billing from "./Billing";

const Setting = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Role Access");

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "Setting",
        linkLocation: `settings/${selectedTab}`,
        ClickLink: `settings / ${selectedTab}`,
      })
    );
  }, [dispatch, selectedTab]);

  const tabs = [
    "Role Access",
    "Access Management",
    "KPI & Metrics",
    "Organization Hierarchy",
    "Billing",
  ];

  return (
    <div className="relative h-screen p-4">
      <div className="flex items-center space-x-16 px-5">
        {tabs.map((tab) => (
          <h1
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`relative cursor-pointer ${
              selectedTab === tab ? "text-orange-500 font-sans" : ""
            }`}
          >
            {tab}
            {selectedTab === tab && (
              <span className="absolute left-0 bottom-[-10px] w-full h-[1px] bg-orange-500 transition-all duration-300 ease-in-out"></span>
            )}
          </h1>
        ))}
      </div>
      <div className="border-b-2 border-gray-300 mb-8 mt-2"></div>

      <div
        className="flex flex-col p-4"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "75vh",
        }}
      >
        {selectedTab === "Role Access" && <RoleAccess />}
        {selectedTab === "Access Management" && <AccessManagement />}
        {selectedTab === "KPI & Metrics" && <KPIMetrics />}
        {selectedTab === "Organization Hierarchy" && <OrganizationHierarchy />}
        {selectedTab === "Billing" && <Billing />}
      </div>
    </div>
  );
};

export default Setting;
