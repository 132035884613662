import React from "react";
import { formatDate } from "../../utils/helpers";
import { Link } from "react-router-dom";

const DashboardHeader = ({ dashboardTitle, LinkLocation, ClickLink }) => {
  return (
    <div className="flex justify-between mb-5 font-lato">
      <span
        style={{ color: "#063F5D", opacity: "90%" }}
        className="font-bold text-4xl"
      >
        {dashboardTitle}
        <sup
          style={{
            marginLeft: "5px",
            fontSize: "18px",
            verticalAlign: "super",
          }}
        >
          <div className="relative inline-flex">
            <div className="w-2 h-2 bg-basic rounded-full"></div>
            <div className="w-2 h-2 bg-basic rounded-full absolute top-0 left-0 animate-ping"></div>
            <div className="w-2 h-2 bg-basic rounded-full absolute top-0 left-0 animate-pulse"></div>
          </div>
        </sup>
        <div
          className="font-medium text-sm italic cursor-pointer whitespace-nowrap"
          style={{
            color: "rgba(6, 63, 93, 0.8)",
          }}
        >
          <p>{ClickLink}</p>
        </div>
      </span>
    </div>
  );
};

export default DashboardHeader;
