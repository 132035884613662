import React, { useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Input,
} from "@chakra-ui/react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const RoleAccess = () => {
  const [role, setRole] = useState("");
  const [team, setTeam] = useState("");
  const [collaborate, setCollaborate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const members = [
    {
      name: "John Doe",
      email: "johndoe@gmail.com",
      role: "Manager",
      department: "Marketing",
      image: "/assets/images/member_1.png",
    },
    {
      name: "Jane Smith",
      email: "janesmith@gmail.com",
      role: "Member",
      department: "Engineering",
      image: "/assets/images/member_1.png",
    },
    {
      name: "Michael Johnson",
      email: "michaeljohnson@gmail.com",
      role: "Admin",
      department: "Sales",
      image: "/assets/images/member_1.png",
    },
  ];

  const filteredMembers = members?.filter((member) =>
    member?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };

  const handleCollaborateChange = (event) => {
    setCollaborate(event.target.value);
  };

  return (
    <div className="overflow-y-auto">
      <CustomScrollbar>
        <div className="p-6 bg-white rounded-lg shadow-lg space-y-10 font-sans">
          <div className="space-y-2">
            <h1 className="text-3xl font-semibold">User Management</h1>
            <p className="text-gray-600 font-medium">
              Manage your team members and their account permissions here.
            </p>
          </div>

          <div className="border-t border-gray-300"></div>
          <div className="flex items-end justify-between space-x-5">
            <div className="space-y-1 w-1/3 ">
              <h1 className="text-2xl font-semibold">Invite Members</h1>
              <p className="text-gray-500 text-base">
                Get your projects up and running faster by inviting your team
                members.
              </p>
            </div>

            <Input
              type="email"
              placeholder="john@example.com"
              className="flex-1 bg-transparent text-gray-700 text-sm h-12 "
            />

            <Box width="12rem">
              <FormControl id="team">
                <FormLabel>
                  <p className="text-sm text-gray-500">Team</p>
                </FormLabel>
                <Select
                  value={team}
                  onChange={handleTeamChange}
                  className="h-12"
                >
                  <option value="Marketing">Marketing</option>
                  <option value="Sales">Sales</option>
                  <option value="Engineering">Engineering</option>
                </Select>
              </FormControl>
            </Box>

            <Box width="10rem">
              <FormControl id="role">
                <FormLabel>
                  <p className="text-sm text-gray-500">Role</p>
                </FormLabel>
                <Select
                  value={role}
                  onChange={handleRoleChange}
                  className="h-12"
                >
                  <option value="Admin">Admin</option>
                  <option value="Manager">Manager</option>
                  <option value="Member">Member</option>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="flex justify-end">
            <button
              className="bg-[#0081F9] text-white rounded-lg p-2 px-6 flex items-center space-x-2"
              onClick={() => console.log("Invite Sent")}
            >
              <EmailOutlinedIcon />
              <p className="font-semibold">Send Invite</p>
            </button>
          </div>

          <div className="border-t border-gray-300"></div>

          {/* Team Collaboration Section */}
          <div className="flex items-end justify-between space-x-5">
            <div className="space-y-1 w-1/3 ">
              <h1 className="text-2xl font-semibold">Team Collaboration</h1>
              <p className="text-gray-500 text-base">
                Accelerate your projects by bringing your team on board to
                collaborate.
              </p>
            </div>

            <Input
              type="email"
              placeholder="john@example.com"
              className="flex-1 bg-transparent text-gray-700 text-sm h-12 "
            />

            <Box width="12rem">
              <FormControl id="team">
                <FormLabel>
                  <p className="text-sm text-gray-500">Team</p>
                </FormLabel>
                <Select
                  value={team}
                  onChange={handleTeamChange}
                  className="h-12"
                >
                  <option value="Marketing">Marketing</option>
                  <option value="Sales">Sales</option>
                  <option value="Engineering">Engineering</option>
                </Select>
              </FormControl>
            </Box>

            <Box width="10rem">
              <FormControl id="collaborate">
                <FormLabel>
                  <p className="text-sm text-gray-500">Collaborate with</p>
                </FormLabel>
                <Select
                  placeholder="Team Name"
                  value={collaborate}
                  onChange={handleCollaborateChange}
                  className="h-12"
                >
                  <option value="Admin">Admin</option>
                  <option value="Manager">Manager</option>
                  <option value="Member">Member</option>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="flex justify-end">
            <button
              className="bg-[#0081F9] text-white rounded-lg p-2 px-6 flex items-center space-x-2"
              onClick={() => console.log("Invite Sent")}
            >
              <EmailOutlinedIcon />
              <p className="font-semibold">Send Invite</p>
            </button>
          </div>

          <div className="border-t border-gray-300"></div>

          {/* Member section */}
          <div className="flex flex-col space-y-4">
            <div className="flex items-center justify-between">
              <div className="space-y-1 w-1/3">
                <h1 className="text-2xl font-semibold">Members</h1>
                <p className="text-gray-500 text-base">
                  Manage your existing members and change their
                  Role/Permissions.
                </p>
              </div>

              {/* Search Bar with Icon */}
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search members..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-4 pr-10 py-2 border border-gray-300 rounded-lg text-sm text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pt-2 pr-3">
                  <SearchRoundedIcon
                    className="text-gray-400"
                    fontSize="small"
                  />
                </div>
              </div>
            </div>
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 py-4 text-left text-gray-500 font-semibold rounded-tl-lg">
                    Name
                  </th>
                  <th className="p-2 py-4 text-left text-gray-500 font-semibold">
                    Role
                  </th>
                  <th className="p-2 py-4 text-left text-gray-500 font-semibold">
                    Department
                  </th>
                  <th className="p-2 py-4 text-left text-gray-500 font-semibold rounded-tr-lg">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {members?.map((member, index) => (
                  <tr
                    key={index}
                    className={`border-b border-gray-200 ${
                      index === members?.length - 1
                        ? "rounded-bl-lg rounded-br-lg"
                        : ""
                    }`}
                  >
                    <td className="p-2 py-5 font-semibold text-sm text-gray-700">
                      <div className="flex items-center space-x-3">
                        <img
                          src={member?.image}
                          className="w-8 h-8"
                          alt={member?.name}
                        />
                        <div className="flex flex-col">
                          <h1 className="font-semibold text-sm text-gray-800">
                            {member?.name}
                          </h1>
                          <h1 className="text-gray-500 font-semibold text-xs">
                            {member?.email}
                          </h1>
                        </div>
                      </div>
                    </td>
                    <td className="p-2 py-5 font-semibold text-sm text-gray-700">
                      {member?.role}
                    </td>
                    <td className="p-2 py-5 font-semibold text-sm text-gray-700">
                      {member?.department}
                    </td>
                    <td className="p-2 py-5 font-semibold text-sm text-gray-700">
                      <div className="flex space-x-3">
                        <button className="text-red-500">Delete</button>
                        <button className="text-blue-500">Modify</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default RoleAccess;
