import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvatarColor,
  getInitials,
} from "../../utils/helpers";
import { baseURL } from "../../utils/config";
import axios from "axios";
import {
  MdOutlineModeEditOutline,
} from "react-icons/md";
import { updateUserLogin } from "../../stores/slices/userAuthSlice";

const ProfileModal = ({ setProfileModal, profileModal }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const [imageLoading, setImageLoading] = useState(false);

  const iconStyle = {
    color: "black",
    fontSize: "25px",
  };
  const [formData, setformData] = useState({
    user_name: "",
    email: "",
    companyName: "",
    image: "",
  });
  const [loading, setLoading] = useState(false);

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile_image", file);
      try {
        setImageLoading(true);
        setformData({
          ...formData,
          image: "",
        });
        const result = await axios.post(baseURL + "/profile-photo/", formData);
        if (result.status === 201) {
          setImageLoading(false);
          setformData({
            ...formData,
            image: result.data.image_url,
          });
        }
      } catch (error) {
        setImageLoading(false);
        // Handle errors
      }
    }
  };

  useEffect(() => {
    if (currentEmployee && currentUser) {
      setformData({
        image: currentUser?.profile_image,
        user_name: currentUser?.username,
        email: currentUser?.email,
        companyName: currentEmployee?.company_name,
      });
    }
  }, [currentEmployee,currentUser,profileModal]);

  const handleSubmit = async () => {
    const payload = {
      user_id: currentEmployee?.user_id,
      company_id: currentEmployee?.company_id,
    };

    if (currentEmployee?.username !== formData.user_name) {
      payload.username = formData.user_name;
    }

    if (currentEmployee?.profile_image !== formData.image) {
      payload.profile_image = formData.image;
    }

    if (currentEmployee?.company_name !== formData.companyName) {
      payload.company_name = formData.companyName;
    }

    try {
      setLoading(true);
      const result = await axios.put(baseURL + "/signin/", payload);
      if (result.status === 200) {
        setLoading(false);
        dispatch(updateUserLogin(result?.data));
        setProfileModal(false);
      }
    } catch (error) {
      setLoading(false);
      // Handle errors
    }
  };

  return (
    <Modal
      open={profileModal}
      onCancel={() => setProfileModal(false)}
      onOk={handleSubmit}
      okText="Update"
      width={550}
      // confirmLoading={loading}
    >
      <div className="p-4 pr-6 w-full">
        <h1 className="text-2xl font-semibold mb-2">Your Profile</h1>
        <p className="text-xs mb-4">Edit Your Profile</p>
        <form className="grid grid-cols-4 gap-4 items-start">
          <div className="col-span-4">
            <div className="col-span-2 flex items-center justify-center flex-col">
              <div
                className="w-24 h-24 border text-3xl border-black rounded-full flex items-center justify-center text-white  relative"
                style={{
                  backgroundColor: formData?.image
                  ? "initial"
                  : getAvatarColor(formData?.user_name),
                }}
                size={30}
              >
                {formData?.image ? (
                  <img
                    src={formData.image}
                    className="w-full h-full object-cover rounded-full"
                    alt="Profile"
                  />
                ) : (
                  getInitials(formData?.user_name)
                )}
                <div className="absolute bottom-0 right-0 z-1">
                  <div className="bg-white rounded-full w-9 border h-9 border-black flex justify-center items-center">
                    <label htmlFor="image-upload">
                      {imageLoading ? (
                        <Spin style={iconStyle} />
                      ) : (
                        <MdOutlineModeEditOutline style={iconStyle} />
                      )}
                    </label>
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="username-input" className="text-sm block">
                Username
              </label>
              <input
                type="text"
                name="user_name"
                id="user_name"
                onChange={changeHandeler}
                value={formData.user_name}
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email-input" className="text-sm block">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData?.email}
                className="w-full"
                disabled
              />
            </div>
            <div>
              <label htmlFor="name-input" className="text-sm block">
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                id="companyName"
                onChange={changeHandeler}
                value={formData.companyName}
                className="w-full"
                disabled={currentEmployee?.role !== "manager"}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ProfileModal;
