import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Textarea,
  Box,
  HStack,
  Text,
  IconButton,
  Input,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { getAvatarColor, getInitials } from "../../utils/helpers";

const TemplateModal = ({
  setTemplateModal,
  template,
  description: initialDescription,
  templateModal,
  data,
  primaryColor,
  secondaryColor,
  handleUpdateTemplate,
  title: initialTitle,
  readOnly = "false",
  handleSchedule,
}) => {
  const initialSections = data?.map((item) => ({
    question_title: item?.question_title,
    questions: item?.question_list?.map((q) => ({
      text: q.text,
      answer: q.answer,
    })),
  }));
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state.user);
  const currentEmployee = currentUser?.employees[user?.currentCompany];
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [sections, setSections] = useState(initialSections);

  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);
  const [isTitleChanged, setIsTitleChanged] = useState(false);
  const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);

  useEffect(() => {
    if (template?.comment_text && template?.comment_text !== "") {
      setComments((prevComments) => [
        ...prevComments,
        {
          id: currentUser?.id,
          username: currentUser?.username,
          email: currentUser?.email,
          comment: template?.comment_text,
          avatar: currentUser?.profile_image,
        },
      ]);
    }
  }, []);

  const handleCommentSubmit = () => {
    if (!readOnly) {
      setComments([
        ...comments,
        {
          id: currentUser?.id,
          username: currentUser?.username,
          email: currentUser?.email,
          comment: newComment,
          avatar: currentUser?.profile_image,
        },
      ]);
      setNewComment("");
    }
  };

  const handleSectionTitleChange = (index, value) => {
    if (!readOnly) {
      const updatedSections = [...sections];
      updatedSections[index].question_title = value;
      setSections(updatedSections);
    }
  };

  const handleQuestionChange = (sectionIndex, questionIndex, field, value) => {
    if (!readOnly) {
      const updatedSections = [...sections];
      updatedSections[sectionIndex].questions[questionIndex][field] = value;
      setSections(updatedSections);
    }
  };

  const handleDeleteSection = (sectionIndex) => {
    if (!readOnly) {
      const updatedSections = sections.filter((_, idx) => idx !== sectionIndex);
      setSections(updatedSections);
    }
  };

  const handleDeleteQuestion = (sectionIndex, questionIndex) => {
    if (!readOnly) {
      const updatedSections = [...sections];
      updatedSections[sectionIndex].questions = updatedSections[
        sectionIndex
      ].questions.filter((_, idx) => idx !== questionIndex);
      setSections(updatedSections);
    }
  };

  const handleAddQuestion = (sectionIndex) => {
    if (!readOnly) {
      const updatedSections = [...sections];
      updatedSections[sectionIndex].questions.push({ text: "", answer: "" }); // Add both text and answer fields
      setSections(updatedSections);
    }
  };

  const handleAddSection = () => {
    if (!readOnly) {
      setSections([
        ...sections,
        {
          question_title: "",
          questions: [{ text: "", answer: "" }], // Initialize with both fields
        },
      ]);
    }
  };

  const handleTitleChange = (e) => {
    if (!readOnly) {
      setTitle(e.target.value);
      setIsTitleChanged(e.target.value !== initialTitle);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setIsDescriptionChanged(e.target.value !== initialDescription);
  };

  const areSectionsChanged = () => {
    return sections.some((section, sectionIndex) => {
      const initialSection = initialSections[sectionIndex];
      if (section.question_title !== initialSection.question_title) {
        return true;
      }
      return section.questions.some((question, questionIndex) => {
        return question.text !== initialSection.questions[questionIndex].text;
      });
    });
  };

  const isSaveDisabled =
    readOnly ||
    !title?.trim() ||
    !isTitleChanged ||
    sections.some(
      (section) =>
        !section?.question_title?.trim() ||
        section?.questions?.some((question) => !question?.text?.trim())
    );

  const isScheduleDisabled =
    readOnly || isTitleChanged || isDescriptionChanged || areSectionsChanged();

  const handleSave = () => {
    if (!readOnly) {
      setLoading(true);

      const payload = {
        user_id: currentEmployee?.user_id,
        company_id: currentEmployee?.company_id,
        template_id: template?.id,
        description: description,
        template_title: title,
        comment_text: comments[0]?.comment,
        template_type: template?.template_type,
        questions: sections?.map((section) => ({
          question_title: section?.question_title,
          question_list: section?.questions?.map((question) => ({
            text: question.text,
            answer: question.answer, // Include answer in the payload
          })),
        })),
      };

      console.log("Payload to be sent:", payload);
      handleUpdateTemplate(payload);

      setTimeout(() => {
        setLoading(false);
        setTemplateModal(false);
      }, 1000);
    }
  };

  return (
    <Modal
      isOpen={templateModal}
      onClose={() => setTemplateModal(false)}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent h="80vh">
        <ModalHeader
          bg={primaryColor}
          borderTopLeftRadius="md"
          borderTopRightRadius="md"
          style={{
            backgroundColor: `${secondaryColor}`,
          }}
        >
          <Input
            value={title}
            onChange={handleTitleChange}
            placeholder="Enter title..."
            border="none"
            boxShadow="none"
            _focus={{ border: "none", boxShadow: "none" }}
            backgroundColor="transparent"
            color="#4F5762"
            fontSize="2xl"
            fontWeight="semibold"
            className="outline-none"
            readOnly={readOnly}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          overflowY="auto"
          maxH="calc(80vh - 120px)"
          p={6}
          backgroundColor={primaryColor}
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
          }}
        >
          <div>
            <label className="text-md">Description</label>
            <Input
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Enter Description..."
              border="none"
              boxShadow="none"
              _focus={{ border: "none", boxShadow: "none" }}
              backgroundColor="transparent"
              color="#4F5762"
              fontSize="md"
              fontWeight="semibold"
              className="outline-none"
              readOnly={readOnly}
            />
          </div>
          {!readOnly && (
            <p className="text-sm mb-6 text-center font-lato italic">
              Answer the following questions with your team.
              <br />
              <p className="text-red-600 text-xs text-center font-lato ">
                *Please ensure all fields are completed and update the title
                accordingly.{" "}
              </p>
            </p>
          )}
          <div className="mb-6">
            {sections?.map((section, sectionIndex) => (
              <div key={sectionIndex} className="mb-4">
                <div className="flex items-center justify-between mb-4">
                  <input
                    type="text"
                    value={section?.question_title}
                    onChange={(e) =>
                      handleSectionTitleChange(sectionIndex, e.target.value)
                    }
                    className="text-lg font-lato bg-transparent font-bold border-none text-black outline-none w-full"
                    placeholder="Enter section title..."
                    readOnly={readOnly}
                  />
                  {!readOnly && (
                    <IconButton
                      icon={<IoClose />}
                      backgroundColor={"transparent"}
                      _hover={{ backgroundColor: "transparent" }}
                      onClick={() => handleDeleteSection(sectionIndex)}
                      size="sm"
                    />
                  )}
                </div>
                <ol className="list-decimal pl-6 space-y-8 text-gray-700">
                  {section?.questions?.map((question, questionIndex) => (
                    <>
                      <li key={questionIndex} className="flex items-center">
                        <p className="flex-shrink-0 mr-2 flex items-center justify-center">
                          {questionIndex + 1}.
                        </p>
                        <input
                          type="text"
                          value={question?.text}
                          onChange={(e) =>
                            handleQuestionChange(
                              sectionIndex,
                              questionIndex,
                              "text",
                              e.target.value
                            )
                          }
                          className="border-none bg-transparent mt-0 w-full flex items-center justify-center"
                          placeholder="Enter question..."
                          readOnly={readOnly}
                        />

                        {!readOnly && (
                          <IconButton
                            icon={<IoClose />}
                            backgroundColor={"transparent"}
                            _hover={{ backgroundColor: "transparent" }}
                            onClick={() =>
                              handleDeleteQuestion(sectionIndex, questionIndex)
                            }
                            size="sm"
                            ml={2}
                          />
                        )}
                      </li>
                      {readOnly && (
                        <span className=" w-full p-2 rounded">
                          {question?.answer}
                        </span>
                      )}
                    </>
                  ))}
                </ol>
                {!readOnly && (
                  <Button
                    leftIcon={<FaPlus />}
                    onClick={() => handleAddQuestion(sectionIndex)}
                    backgroundColor={secondaryColor}
                    textColor={"#4F5762"}
                    px={"28px"}
                    borderRadius={"20px"}
                    size="sm"
                    mt={2}
                  >
                    Add Question
                  </Button>
                )}
              </div>
            ))}
            {!readOnly && (
              <Button
                leftIcon={<FaPlus />}
                onClick={handleAddSection}
                backgroundColor={secondaryColor}
                textColor={"#4F5762"}
                px={"28px"}
                borderRadius={"20px"}
                size="sm"
                mt={2}
              >
                Add Section
              </Button>
            )}
          </div>
          <hr />
          {readOnly ? (
            // <CommentSectionTemplate data={template} />
            <></>
          ) : (
            <Box mt={4}>
              <Text
                fontSize="xl"
                textAlign={"center"}
                fontWeight="semibold"
                mb={4}
              >
                Comment
              </Text>
              <Box maxH="300px" overflowY="auto" mb={4}>
                {comments?.map((comment) => (
                  <HStack key={comment?.id} alignItems="flex-start" mb={4}>
                    <Box className="grid grid-cols-6 space-x-2 w-full">
                      <Box className="flex col-span-2  justify-center items-center space-x-2">
                        <div
                          className="w-6 h-6 border text-sm border-black rounded-full flex items-center justify-center text-white  relative"
                          style={{
                            backgroundColor: comment?.profile_image
                              ? "initial"
                              : getAvatarColor(comment?.username),
                          }}
                          size={30}
                        >
                          {comment?.profile_image ? (
                            <img
                              src={comment?.profile_image}
                              className="w-full h-full object-cover rounded-full"
                              alt="Profile"
                            />
                          ) : (
                            getInitials(comment?.username)
                          )}
                        </div>
                        <Box className="flex-col  items-end justify-center">
                          <Text fontWeight="bold">{comment?.username}</Text>
                          <Text fontSize="xs" color="gray.500">
                            {comment?.email}
                          </Text>
                        </Box>
                      </Box>
                      <Box className="col-span-4 ">
                        <Text
                          className="w-full"
                          padding={"20px"}
                          backgroundColor={"white"}
                          boxShadow={"3px 4px 24px 0px rgba(0, 0, 0, 0.05)"}
                        >
                          {comment?.comment}
                        </Text>
                      </Box>
                    </Box>
                  </HStack>
                ))}
              </Box>
              <HStack>
                <div
                  className="w-6 h-6 border text-sm border-black rounded-full flex items-center justify-center text-white  relative"
                  style={{
                    backgroundColor: currentUser?.profile_image
                      ? "initial"
                      : getAvatarColor(currentUser?.username),
                  }}
                  size={30}
                >
                  {currentUser?.profile_image ? (
                    <img
                      src={currentUser?.profile_image}
                      className="w-full h-full object-cover rounded-full"
                      alt="Profile"
                    />
                  ) : (
                    getInitials(currentUser?.username)
                  )}
                </div>
                <Textarea
                  placeholder="Write your comment here..."
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                />
              </HStack>
              <Box display="flex" justifyContent="flex-end" mt={4}>
                {!readOnly && (
                  <Button
                    onClick={handleCommentSubmit}
                    backgroundColor={secondaryColor}
                    color="#4F5762"
                    px={"28px"}
                    borderRadius={"20px"}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </ModalBody>
        <ModalFooter
          position="sticky"
          bottom={0}
          bg="white"
          borderTop="1px solid #E2E8F0"
          boxShadow="0px -4px 8px -4px rgba(0, 0, 0, 0.1)"
          p={4}
        >
          {!readOnly && (
            <Button
              variant="outline"
              borderRadius={"20px"}
              mr={3}
              px={"28px"}
              onClick={() => {
                setTemplateModal(false);
                handleSchedule();
              }}
              isDisabled={isScheduleDisabled}
            >
              New Schedule
            </Button>
          )}
          <Button
            variant="outline"
            borderRadius={"20px"}
            mr={3}
            px={"28px"}
            onClick={() => setTemplateModal(false)}
          >
            Cancel
          </Button>
          {!readOnly && (
            <Button
              backgroundColor="#FFF1E3"
              textColor={"#F27E0D"}
              px={"28px"}
              borderRadius={"20px"}
              border={"none"}
              onClick={() => {
                handleSave();
              }}
              isLoading={loading}
              isDisabled={isSaveDisabled}
            >
              Save
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TemplateModal;
