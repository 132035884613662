import React from "react";
import { formatDate } from "../../utils/helpers";

const EmployeeHeader = ({ companyName, initials, Name, Title }) => {
  const currentTime = new Date().getHours();
  let greeting;

  if (currentTime >= 5 && currentTime < 12) {
    greeting = "Good Morning";
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  return (
    <div className="flex justify-between mb-2">
      <h1
        className="font-medium text-black flex justify-end"
        style={{ color: "#0F7BB3", opacity: "80%" }}
      >
        {Name ? (
          <span className="flex flex-col justify-around">
            <div className="text-2xl font-semibold">
              {greeting}, {Name}
            </div>
          </span>
        ) : initials ? (
          <div className="flex items-center gap-x-2 select-option-owner">
            <div
              className="w-10 h-10 border rounded-full flex items-center justify-center relative text-lg"
              style={{
                color: "#0F7BB3",
                borderColor: "#0F7BB3",
              }}
            >
              {initials}
            </div>
            <span className="flex flex-col justify-around">
              <div className="text-2xl font-semibold">{companyName}</div>
            </span>
          </div>
        ) : (
          <span className="flex flex-col justify-around">
            <div className="text-2xl font-semibold">{Title}</div>
          </span>
        )}
        <span className="flex items-center justify-start">
          <svg
            width="118"
            height="25"
            viewBox="0 0 118 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              strokeWidth: "1.32067",
              verticalAlign: "middle",
            }}
          >
            <path
              d="M1 18.587C5.7027 16.2018 11.4706 17.5503 16.6757 19.5737C43.9868 30.191 32.5107 7.20981 48.027 7.20981C59 7.20981 59.7838 33.0617 74.6757 11.3311C89.5676 -10.3994 80.1622 23.7467 95.0461 23.7467C109.237 23.7467 104.675 7.20981 117 1.58984"
              stroke="url(#paint0_linear_385_2121)"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_385_2121"
                x1="16.225"
                y1="18.946"
                x2="117.186"
                y2="-14.0898"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FEF7F1" stopOpacity="0" />
                <stop offset="0.749947" stopColor="#FB4646" />
              </linearGradient>
            </defs>
          </svg>
        </span>
      </h1>
      <span
        className="flex justify-end items-center font-medium font-lato"
        style={{
          color: "#419EBD",
        }}
      >
        {formatDate()}
      </span>
    </div>
  );
};

export default EmployeeHeader;
